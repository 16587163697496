import React from "react";
import HeaderAfterLogin from "../../../../containers/HeaderAfterLogin";
import Footer from "../../../../containers/Footer";
import { Col, Container, Row } from "react-bootstrap";
import numberWithCommas from "../../../../utils/numbersWithComma";
import { useSelector } from "react-redux";

import Coins from "../../../../assets/images/Coin.png";

const Wallet = () => {
  const { wallet } = useSelector((state) => state.auth);
  return (
    <>
      <HeaderAfterLogin />

      <main className="main walletSection">
        <Container>
          <div className="d-flex justify-content-center mt-5 mb-4 walletLeftSection">
            <div className="wallet-leftpart">
              <div className="transfer_details">
                <h5 className="mt-3 mb-4 text-center">Wallet</h5>

                <div className="wallet-details">
                  <ul>
                    <li>
                      <h6>Balance</h6>
                      <span>INR {numberWithCommas(wallet?.cash || 0)}</span>
                    </li>
                    <li>
                      <h6>Bonus</h6>
                      <span>INR {numberWithCommas(wallet?.coins)}</span>
                    </li>
                    <li>
                      <a
                        href="/gatewaylist"
                        className="deposit btn btn-primary"
                      >
                        Deposit
                      </a>
                      <a href="/withdraw" className="withdraw btn btn-primary">
                        Withdraw
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="deposit_img">
              <img src={Coins} />
            </div>
          </div>
        </Container>
      </main>

      <Footer />
    </>
  );
};

export default Wallet;
